import { action, transaction } from "mobx";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import { notificationsStore } from "common/stores";
import IAdvertiser from "../interfaces/IAdvertiser";

export default abstract class BaseAdvertiserStore extends BaseItemStore<IAdvertiser> {

	constructor(fromDuplicate?: IAdvertiser) {
		super(notificationsStore, "advertiser");

		this.reset();

		if (fromDuplicate) {
			this.setItem(fromDuplicate);
		}
	}

	@action public reset() {
		transaction(() => {
			super.reset();

			this.item = {
				...this.item,
				name: "",
				description: "",
				sellerAccountId: "",
				exchangeHost: "",
				moneybookerAccount: ""
			};

		});
	}
}
