import * as React from "react";
import EditAdvertiserStore from "modules/advertisers/stores/EditAdvertiserStore";
import CreateAdvertiserStore from "modules/advertisers/stores/CreateAdvertiserStore";

export interface IAdvertiserContext {

	store: EditAdvertiserStore | CreateAdvertiserStore;

}

export default React.createContext<IAdvertiserContext>({

	store: null

});
