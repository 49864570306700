class AppUrlsService {

	public app(): string {
		return `/`;
	}

	public accounts(): string {
		return `/accounts`;
	}

	public account(): string {
		return `/account`;
	}

	public createAccount(): string {
		return `${this.account()}/create`;
	}

	public editAccount(id: string = ":id"): string {
		return `${this.account()}/${id}/edit`;
	}

	public advertisers(): string {
		return `/advertisers`;
	}

	public advertiser(): string {
		return `/advertiser`;
	}

	public createAdvertiser(): string {
		return `${this.advertiser()}/create`;
	}

	public editAdvertiser(id: string = ":id"): string {
		return `${this.advertiser()}/${id}/edit`;
	}

	public companies(): string {
		return `/companies`;
	}

	public company(): string {
		return `/company`;
	}

	public createCompany(): string {
		return `${this.company()}/create`;
	}

	public editCompany(id: string = ":id"): string {
		return `${this.company()}/${id}/edit`;
	}
}

export default new AppUrlsService();
