import * as React from "react";
import { IDictionary } from "@vidazoo/ui-framework";
import {
	SortDirection,
	Density,
	IDataGridHeader,
	DataGridRow,
	DataGridHeaderColumn,
	DataGrid,
	Checkbox
} from "@vidazoo/ui";
import IAdvertiser from "../../interfaces/IAdvertiser";
import AdvertiserRow from "./AdvertiserRow";
import * as theme from "./theme.scss";

export interface IDataGridHeaderPlaylist extends IDataGridHeader {
}

const headers: IDataGridHeaderPlaylist[] = [{
	label: "Name",
	sortBy: "name",
	searchKey: "name"
}, {
	label: "Date",
	sortBy: "created",
	searchKey: "created",
}, {
	label: "Updated Date",
	sortBy: "updated",
	searchKey: "updated",
}, {
	label: "Active",
	sortBy: "isActive",
	searchKey: "isActive",
}, {
	label: "Actions",
}];

export interface IAdvertisersListProps {
	items?: IAdvertiser[];
	searchQueries: IDictionary<string>;
	sortBy: string;
	sortDirection: SortDirection;
	onSort: (sortBy: string) => void;
	onActiveChange: (item: IAdvertiser) => void;
	onSearch: (searchKey: string, value: string) => void;
	onDelete: (item: IAdvertiser) => void;
	onEdit: (item: IAdvertiser) => void;
	density: Density;
	loadMore?: () => void;
	toggleItem: (id: string) => void;
	toggleAllItems: () => void;
	allItemsChecked: boolean;
	selectedValues: string[];
	setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
	getColumnsWidth: () => { [index: string]: number };
}

export interface IListState {
}

export default class List extends React.Component<IAdvertisersListProps, IListState> {

	constructor(props, context) {
		super(props, context);
	}

	private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex) => {
		const {sortDirection, onSearch, sortBy, onSort, searchQueries, allItemsChecked, toggleAllItems} = this.props;
		const filteredHeaders = headers.filter((header) => {
			return header;
		});
		return (
			<DataGridRow columnsWidth={columnsWidth}>
				<DataGridHeaderColumn columnsWidth={columnsWidth}>
					<Checkbox checked={allItemsChecked} onChange={toggleAllItems}/>
				</DataGridHeaderColumn>

				{filteredHeaders.map((header: IDataGridHeader) => {
					const props = {
						blue: true,
						label: header.label,
						sortable: !!header.sortBy,
						sortBy: header.sortBy,
						sortActive: sortBy === header.sortBy,
						searchKey: header.searchKey,
						searchable: !!header.searchKey,
						searchQuery: searchQueries[header.searchKey || ""],
						freezeColumnIndex,
						columnsWidth,
						setFreezeColumn,
						sortDirection,
						onSort,
						onSearch,
					};
					return (
						<DataGridHeaderColumn {...props}/>
					);
				})}
			</DataGridRow>
		);
	}

	private onColumnResize = (columnsWidth) => {
		this.props.setColumnsWidth(columnsWidth);
	}

	private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
		const {items, onActiveChange, onEdit, onDelete, sortBy, toggleItem, selectedValues} = this.props;

		return (<AdvertiserRow
			item={items[index]}
			sortBy={sortBy}
			onActiveChange={onActiveChange}
			onEdit={onEdit}
			onDelete={onDelete}
			onToggle={toggleItem}
			isSelected={selectedValues.indexOf(items[index]._id) > -1}
			columnsWidth={columnsWidth}
			freezeColumnIndex={freezeColumnIndex}
			index={index}
			key={index}
		/>);
	}

	public render() {
		const {items, getColumnsWidth} = this.props;
		const columnsWidth = getColumnsWidth();

		return (
			<DataGrid
				onColumnResize={this.onColumnResize}
				className={theme.list}
				extraData={items}
				headersCount={headers.length}
				itemsCount={items.length}
				renderRow={this.renderRow}
				rowHeight={40}
				columnsWidth={columnsWidth}
				renderHeader={this.renderHeaderAndFooter}/>
		);
	}
}
