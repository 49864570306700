import IMenuEntry from "common/interfaces/IMenuEntry";
import { DEFAULT_ACCESS } from "./enums";

export default {
	iconName: "icon-settings",
	access: DEFAULT_ACCESS,
	items: [
		{
			title: "Account Settings",
			link: { to: "" },
			access: DEFAULT_ACCESS
		},
		{
			title: "System Settings",
			link: { to: "" },
			access: DEFAULT_ACCESS,
		}
	]
} as IMenuEntry;
