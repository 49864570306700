import * as React from "react";
import classnames from "classnames";
import * as theme from "styles/_baseForm.scss";
import {observer} from "mobx-react";
import {FormInput, Label, AggregateChangeComponent, Checkbox, FormChips, FormSelect} from "@vidazoo/ui";
import {FormMode, normalizeEventValue} from "@vidazoo/ui-framework";
import AdvertiserContext, {IAdvertiserContext} from "./AdvertiserContext";
import {AccountTypes} from "../../../../common/enums";

export interface IDetailsFormProps {

	mode: FormMode;

}

export interface IDetailsFormState {

}

@observer
export default class DetailsForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

	public static defaultProps: Partial<IDetailsFormProps> = {};

	// public static contextTypes = {
	//     refreshValidity: PropTypes.func
	// };

	public static contextType = AdvertiserContext;

	constructor(props: IDetailsFormProps, context: IAdvertiserContext) {
		super(props, context);
	}

	private onChangeDetail = (e) => {
		const {store} = this.context;

		const value = normalizeEventValue(e);

		store.updateParam(e.target.name, value);
	}

	public render(): JSX.Element {

		const {store} = this.context;

		return (
			<div className={theme.form}>
				<div className="flex">
					<div className="flex-1">
						<Label size={13} weight={700} spacing={1.25} monserrat>General Details</Label>
					</div>
					<div className={theme.header}></div>
				</div>
				<AggregateChangeComponent onChange={this.onChangeDetail}>
					<div className={theme.row}>
						<FormInput value={store.item.name} autoFocus className={theme.input} name="name" label="Name"
								   required fill/>
					</div>
					<div className={theme.row}>
						<FormInput value={store.item.description} multiline rows={2} className={theme.input}
								   name="description" label="Description" fill/>
					</div>
					<div className={theme.row}>
						<FormInput value={store.item.sellerAccountId} className={theme.input} name="sellerAccountId"
								   label="Seller Account ID"
								   fill/>
					</div>
					<div className={theme.row}>
						<FormInput value={store.item.exchangeHost} className={theme.input} name="exchangeHost"
								   label="Exchange Host"
								   fill/>
					</div>
					<div className={theme.row}>
						<FormSelect className={theme.input} value={store.item.moneybookerAccount}
									name="moneybookerAccount" label="Moneybooker Account" items={AccountTypes}
									fill/>
					</div>
					<div className={classnames(theme.row, "flex")}
						 style={{display: "grid", gridTemplateColumns: "repeat(1fr)", gridGap: "2rem 8rem"}}>
						<Checkbox name="isActive" label="Active" defaultChecked={store.item.isActive}/>
					</div>
				</AggregateChangeComponent>
			</div>
		);
	}
}
