import * as React from "react";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import { AuthRoute } from "common/components";
import { appUrlsService } from "common/services";
import CreateCompany from "./CreateCompany";
import EditCompany from "./EditCompany";

export default (): JSX.Element => (
	<Switch>
		<AuthRoute path={appUrlsService.createCompany()} exact component={CreateCompany} />
		<AuthRoute path={appUrlsService.editCompany()} exact component={EditCompany} />
		<Redirect to={appUrlsService.createCompany()} />
	</Switch>
);
