import * as React from "react";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import { UserRole, SpreadPageLoader, BaseNotificationsStore } from "@vidazoo/ui-framework";
import { AuthRoute } from "common/components";
import { AppHeader } from "../../components";
import { NotificationStack } from "@vidazoo/ui/lib/components/notificationStack";
import * as theme from "./theme.scss";
import { appUrlsService } from "common/services";
import { currentUserStore } from "common/stores";
import UIStore from "common/stores/UIStore";
import Profile from "@vidazoo/ui-framework/lib/containers/profile";
import Advertisers from "../../../advertisers/containers/advertisers/Advertisers";
import Advertiser from "../../../advertisers/containers/advertiser/Advertiser";
import Companies from "../../../companies/containers/companies/Companies";
import Company from "../../../companies/containers/company/Company";

export interface IAppProps {

	userName?: string;

	userEmail?: string;

	userAvatarUrl?: string;

	onSignOut?: () => void;

	notificationsStore: BaseNotificationsStore;

	uiStore?: UIStore;

	userScopes?: string[];

	isVidazooNetwork?: () => boolean;
}

export interface IAppState {

}

export default class App extends React.Component<IAppProps, IAppState> {

	public static defaultProps: Partial<IAppProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	public render(): JSX.Element {
		const { userScopes, userName, userEmail, userAvatarUrl, onSignOut, uiStore, notificationsStore } = this.props;

		return (
			<div className={theme.app}>
				<AppHeader
					{...{
						userName,
						userEmail,
						userAvatarUrl,
						onSignOut,
						openMyProfile: uiStore.toggleProfileModal,
						userScopes
					}}
					isInRoles={currentUserStore.isInRoles}
					isFeatureAllowed={currentUserStore.isFeatureAllowed}
				/>
				<NotificationStack
					verticalGap={6}
					horizontalGap={0}
					notifications={notificationsStore.notifications}
					onClose={notificationsStore.removeNotification}
				/>
				<Profile
					notificationStore={notificationsStore}
					isOpen={uiStore.isProfileModalOpen}
					onClose={uiStore.toggleProfileModal}
				/>
				<div className={theme.pageContainer}>
					<React.Suspense fallback={<SpreadPageLoader blue />}>
						<Switch>
							<AuthRoute path={appUrlsService.advertisers()} component={Advertisers} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
							<AuthRoute path={appUrlsService.advertiser()} component={Advertiser} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
							<AuthRoute path={appUrlsService.companies()} component={Companies} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
							<AuthRoute path={appUrlsService.company()} component={Company} scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]} />
							<Redirect to={appUrlsService.advertisers()} />
						</Switch>
					</React.Suspense>
				</div>
			</div>
		);
	}
}
