import { BaseAPI } from "@vidazoo/ui-framework";

class CompaniesAPI extends BaseAPI {
	public getAll(page: number = 1, pageSize: number = 50, filter: any = {}, fields: any = {}, sort: any = {}) {
		return this.request({
			method: "get",
			url: "/api/companies/",
			params: { page, pageSize, filter, fields, sort }
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/companies/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/companies/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/companies/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/companies/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/companies/${id}/active/`,
			data: { isActive }
		});
	}
}

export default new CompaniesAPI(window.__BASE_API_URL__);
