import { action } from "mobx";
import companiesAPI from "../api/companiesAPI";
import { navigationStore } from "common/stores";
import BaseCompanyStore from "./BaseCompanyStore";
import { appUrlsService } from "common/services";

export default class CreateCompanyStore extends BaseCompanyStore {

	@action public submit() {
		const item = this.setAccounts();

		companiesAPI.create(item)
			.then(() => navigationStore.push(appUrlsService.companies()))
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}
}
