import { Entity } from "../enums";

class DuplicateEntityManager {

	private entityDuplication: { [index: string]: any };

	constructor() {
		this.entityDuplication = {};
	}

	public setEntityDuplication(type: Entity, value: any) {
		this.entityDuplication[type] = value;
	}

	public getEntityDuplication(type: Entity) {
		const duplication = this.entityDuplication[type];

		delete this.entityDuplication[type];

		return duplication;
	}

}

export default new DuplicateEntityManager();
