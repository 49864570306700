import { action } from "mobx";
import * as _ from "lodash";
import applicationsAPI from "../api/advertisersAPI";
import { navigationStore } from "common/stores";
import { appUrlsService } from "common/services";
import { fastClone } from "@vidazoo/ui-framework";
import BaseAdvertiserStore from "./BaseAdvertiserStore";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import { Entity } from "common/enums";

export default class EditAdvertiserStore extends BaseAdvertiserStore {

	@action public getItem(id: string) {
		this.isLoading = true;
		this.loadError = false;

		applicationsAPI.getOne(id)
			.then((item) => this.setItem(item.data))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public submit() {
		applicationsAPI.update(this.item._id, this.item)
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public delete() {
		applicationsAPI.delete(this.item._id)
			.then(() => this.onSubmitSuccess())
			.then(() => navigationStore.push(appUrlsService.advertisers()))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public duplicate() {
		const item = fastClone(this.item);

		_.unset(item, "_id");

		item.name = "";

		duplicateEntityManager.setEntityDuplication(Entity.Advertiser, item);

		navigationStore.push(appUrlsService.createAdvertiser());
	}
}
