import { BaseStorageService } from "@vidazoo/ui-framework";

class StorageService extends BaseStorageService {
	public setColumnsWidth = (entity: string, columnsWidth) => {
		const allColumnsWidth = this.get("columnsWidth", {});
		allColumnsWidth[entity] = columnsWidth;
		this.set(`columnsWidth`, allColumnsWidth);
	}

	public getColumnsWidth = (entity: string) => {
		const allColumnsWidth = this.get("columnsWidth", {});
		return allColumnsWidth[entity];
	}
}

export default new StorageService();
