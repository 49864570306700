import {UserRole} from "@vidazoo/ui-framework";

export enum Entity {
	Advertiser = "advertiser",
	Company = "company",
}

export enum NetworkFeatures {
	VIDAZOO = "vidazoo",
}

export const DEFAULT_ACCESS = [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN];

export const dateFields = ["created", "updated", "date", "from", "to"];

export enum moneyBookerAccounts {
	ALLINVIEWS = "59be3322852eb53236e0baee",
	VIDAZOO = "59ba328d852eb506eee83cbf",
	SAAS_CLIENTS = "5f7c96d274e4ca135d197767",
	NULL = ""
}
export const AccountTypes = [
	{ value: moneyBookerAccounts.ALLINVIEWS, label: "allinviews" },
	{ value: moneyBookerAccounts.VIDAZOO, label: "vidazoo" },
	{ value: moneyBookerAccounts.SAAS_CLIENTS, label: "SaaS clients" },
	{ value: moneyBookerAccounts.NULL, label: "No account" },
];
