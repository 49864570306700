import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { BaseContainer } from "@vidazoo/ui-framework";
import { Surface, Form, Button, PageContainer, SliderPanel, Tab, TabsPanel } from "@vidazoo/ui";
import { FormMode } from "@vidazoo/ui-framework";
import Breadcrumbs, { IBreadcrumb } from "@vidazoo/ui/lib/components/breadcrumbs";
import { appUrlsService } from "common/services";
import CreateAdvertiserStore from "../../stores/CreateAdvertiserStore";
import DetailsForm from "./DetailsForm";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import { Entity } from "common/enums";
import ApplicationContext from "./AdvertiserContext";

export interface ICreateApplicationProps extends RouteComponentProps<{}> {

}

export interface ICreateApplicationState {

}

class CreateAdvertiser extends BaseContainer<ICreateApplicationProps, ICreateApplicationState> {

	public static defaultProps: Partial<ICreateApplicationProps> = {};

	private store: CreateAdvertiserStore;

	constructor(props, context) {
		super(props, context);

		this.store = new CreateAdvertiserStore(
			duplicateEntityManager.getEntityDuplication(Entity.Advertiser)
		);
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Advertisers",
			iconName: "icon-scenarios",
			link: { to: appUrlsService.advertisers() }
		}, {
			label: "Create Advertiser",
			primary: true
		}];
	}

	private onSubmit = () => {
		this.store.submit();
	}

	private onCancelClick = () => {
		const { history } = this.props;

		history.push(appUrlsService.advertisers());
	}

	public render(): JSX.Element {
		return (
			<PageContainer pageTitle="Create Advertiser" inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()} />
				<Form className={theme.form} onSubmit={this.onSubmit}>
					<ApplicationContext.Provider value={{ store: this.store }}>
						<TabsPanel animate selectedIndex={this.state.selectedIndex} onChange={this.onTabChange} className={theme.midContent}>
							<Tab label="Details" hash="details">
								<Surface className={theme.surface} level={1}>
									<DetailsForm mode={FormMode.Create} />
								</Surface>
							</Tab>
						</TabsPanel>
					</ApplicationContext.Provider>
					<SliderPanel>
						<div className={theme.actions}>
							<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
							<Button type="submit" medium blue uppercase>Create</Button>
						</div>
					</SliderPanel>
				</Form>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(CreateAdvertiser));
