import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer, FormMode} from "@vidazoo/ui-framework";
import {
	Surface,
	Label,
	Form,
	Button,
	PageContainer,
	Loader,
	PageMessage,
	Modal,
	ConfirmPopMessage,
	SliderPanel,
	TabsPanel, Tab
} from "@vidazoo/ui";
import Breadcrumbs, {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "common/services";
import {AddItemButton} from "common/components";
import EditCompanyStore from "../../stores/EditCompanyStore";
import DetailsForm from "./DetailsForm";
import CompanyContext from "./CompanyContext";

export interface IEditCompanyProps extends RouteComponentProps<{ id: string }> {
}

export interface IEditCompanyState {
	showDeleteMessage: boolean;
}

class EditCompany extends BaseContainer<IEditCompanyProps, IEditCompanyState> {

	public static defaultProps: Partial<IEditCompanyProps> = {};

	private store: EditCompanyStore;

	constructor(props, context) {
		super(props, context);

		this.store = new EditCompanyStore();

		this.setInitialState({showDeleteMessage: false});
	}

	public componentWillMount() {
		const {match} = this.props;

		this.store.getItem(match.params.id);
	}

	private getTitle(): string {
		return this.store.isLoading
			? "Loading..."
			: this.store.loadError
				? "Error"
				: this.store.item.name;
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Companies",
			iconName: "icon-scenarios",
			link: {to: appUrlsService.companies()}
		}, {
			label: this.getTitle(),
			primary: true
		}];
	}

	private getPageTitle() {
		const title = ["Edit Company", this.getTitle()];

		return title;
	}

	private onSubmit = () => {
		this.store.submit();
	}

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Company"
					description="Please wait..."
				/>
			</div>
		);
	}

	private onCancelClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.companies());
	}

	private onDuplicateClick = () => {
		this.store.duplicate();
	}

	private hideDeleteMessage = () => {
		this.setState({
			showDeleteMessage: false
		});
	}

	private showDeleteMessage = () => {
		this.setState({
			showDeleteMessage: true
		});
	}

	private delete = () => {
		this.hideDeleteMessage();

		this.store.delete();
	}

	private renderDeleteMessage(): JSX.Element {
		const {showDeleteMessage} = this.state;

		return (
			<Modal external exitOnClickOutside onClose={this.hideDeleteMessage} isOpen={showDeleteMessage}>
				<ConfirmPopMessage
					red
					title="Delete Company?"
					text={<span>Are you sure you want to delete <Label weight={600}
																	   block={false}>{this.store.item.name}</Label>?</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Delete"
					onCancelClick={this.hideDeleteMessage}
					onConfirmClick={this.delete}
				/>
			</Modal>
		);
	}

	private renderForm() {
		return (
			<Form className={theme.form} onSubmit={this.onSubmit}>
				<CompanyContext.Provider value={{store: this.store}}>
					<TabsPanel animate selectedIndex={this.state.selectedIndex} onChange={this.onTabChange}
							   className={theme.midContent}>
						<Tab label="Details" hash="details">
							<Surface className={theme.surface} level={1}>
								<DetailsForm mode={FormMode.Edit}/>
							</Surface>
						</Tab>
					</TabsPanel>
				</CompanyContext.Provider>
				<SliderPanel>
					<div className={theme.actions}>
						<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
						<Button type="button" medium red uppercase onClick={this.showDeleteMessage}>Delete</Button>
						<AddItemButton label="Duplicate" medium gray60 uppercase onClick={this.onDuplicateClick}/>
						<Button type="submit" medium blue uppercase>Update</Button>
					</div>
				</SliderPanel>
			</Form>
		);
	}

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="Failed to load company"
					description="We had an error loading the requested company."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		const {match} = this.props;

		return (
			<PageContainer pageTitle={this.getPageTitle()} inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()}/>
				{this.store.isLoading ? this.renderLoader() : null}
				{this.store.loadError ? this.renderError() : null}
				{!this.store.isLoading && !this.store.loadError ? this.renderForm() : null}
				{this.renderDeleteMessage()}
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(EditCompany));
