import {observable, action, transaction, computed} from "mobx";
import accountsAPI from "modules/accounts/api/accountsAPI";
import {socketService} from "common/services";
import IUser from "common/interfaces/IUser";
import {currentUserStore} from "common/stores";
import INetwork from "common/interfaces/INetwork";
import IAccount from "modules/accounts/interfaces/IAccount";
import * as _ from "lodash";

export default class SessionStore {
	@observable public isAuthenticated: boolean;
	@observable public isAuthenticating: boolean;
	@observable public isAuthError: boolean;
	@observable public accessToken: string;
	@observable public selectedAccounts: string[];
	public userScopes: any[];
	public isAuthTimeout: boolean;
	public accounts: IAccount[];
	public indexAccounts: any;

	constructor() {
		window.__sdk__.auth.on("session:change", (err, res) => this.onSessionChange(err, res));

		this.isAuthenticated = false;
		this.isAuthenticating = true;
		this.isAuthError = false;
		this.isAuthTimeout = false;
		this.accessToken = null;
		this.accounts = [];
		this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
		this.indexAccounts = {};
	}

	@action public setSelectedAccounts = (accounts: string[], reload?: boolean) => {
		window.__sdk__.auth.setSelectedAccounts(accounts);
		this.selectedAccounts = accounts;
		currentUserStore.setNetwork(this.indexAccounts[accounts[0]].network);

		if (reload) {
			window.location.reload();
		}
	}

	@computed
	public get userAccounts(): IAccount[] {
		return this.accounts;
	}

	@computed
	public get isMultiAccounts(): boolean {
		return this.accounts.length > 1;
	}

	@computed
	public get isMultiSelectedAccounts(): boolean {
		return this.selectedAccounts.length > 1;
	}

	@action
	private async onSessionChange(err, response) {
		if (response) {
			this.accessToken = response.accessToken;

			try {
				const account = await accountsAPI.me(10 * 1000);
				this.userScopes = response.user.scopes;

				this.onLogin(response.accessToken, account.data);
			} catch (err) {
				this.onLoginFailed(err);
			}
		}
	}

	@action
	public initialize() {
		window.__sdk__.auth.authorize();
	}

	@action public logout = () => {
		window.__sdk__.auth.logout();
	}

	@action
	private onLogin(accessToken: string, data: { auth: IUser, network: INetwork, accounts: IAccount[] }) {
		this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
		this.accounts = data.accounts;
		this.indexAccounts = _.keyBy(this.accounts, "_id");

		if (!this.selectedAccounts.length) {
			window.__sdk__.auth.setSelectedAccounts(data.accounts[0].toString());
			this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
		}

		currentUserStore.initialize({...data.auth, network: this.indexAccounts[this.selectedAccounts[0]].network});
		this.onSocketConnected();
		//
		// socketService.initialize(window.__BASE_API_URL__, this.accessToken)
		// 	.then(() => this.onSocketConnected())
		// 	.catch((err) => this.onLoginFailed(err));
	}

	@action
	private onSocketConnected() {
		transaction(() => {
			this.isAuthenticated = true;
			this.isAuthenticating = false;
			this.isAuthError = false;
			this.isAuthTimeout = false;
		});
	}

	@action
	private onLoginFailed(err) {
		transaction(() => {
			this.accessToken = null;
			this.isAuthenticated = false;
			this.isAuthenticating = false;
			this.isAuthError = true;
			this.isAuthTimeout = err && _.includes(err.toString().toLowerCase(), "timeout");
		});
	}
}
