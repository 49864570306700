import * as React from "react";
import * as moment from "moment";
import * as theme from "./theme.scss";
import { observer } from "mobx-react";
import {Switch, LinkButton, DataGridRow, DataGridColumn, Checkbox} from "@vidazoo/ui";
import { Link } from "react-router-dom";
import { appUrlsService } from "common/services";
import IAdvertiser from "../../interfaces/IAdvertiser";

export interface IAdvertiserRowProps {
	item: IAdvertiser;
	sortBy: string;
	onActiveChange: (item: IAdvertiser) => void;
	onEdit: (item: IAdvertiser) => void;
	onDelete: (item: IAdvertiser) => void;
	onToggle: (id: string) => void;
	isSelected: boolean;
	columnsWidth: any;
	freezeColumnIndex: number;
	index: number;
}

export interface IAdvertiserRowState {

}

@observer
export default class AdvertiserRow extends React.Component<IAdvertiserRowProps, IAdvertiserRowState> {

	public static defaultProps: Partial<IAdvertiserRowProps> = {

	};

	constructor(props, context) {
		super(props, context);
	}

	private onActiveChange = () => {
		const { onActiveChange, item } = this.props;

		onActiveChange(item);
	}

	private onDelete = () => {
		const { onDelete, item } = this.props;

		onDelete(item);
	}

	private onEdit = () => {
		const { item, onEdit } = this.props;

		onEdit(item);
	}

	private onToggle = () => {
		const { item, onToggle } = this.props;

		onToggle(item._id);
	}

	public render(): JSX.Element {

		const {item, index, columnsWidth, freezeColumnIndex, isSelected} = this.props;

		return (
			<DataGridRow className={theme.row} index={index} columnsWidth={columnsWidth} key={index} freezeColumnIndex={freezeColumnIndex}>
				<DataGridColumn className={theme.column}><Checkbox checked={isSelected} onChange={this.onToggle}/></DataGridColumn>
				<DataGridColumn className={theme.column}><Link to={appUrlsService.editAdvertiser(item._id)}>{item.name}</Link></DataGridColumn>
				<DataGridColumn className={theme.column}>{moment(item.created).format("MMM DD, YYYY")}</DataGridColumn>
				<DataGridColumn className={theme.column}>{moment(item.updated).format("MMM DD, YYYY")}</DataGridColumn>
				<DataGridColumn className={theme.column}><Switch small checked={item.isActive} onChange={this.onActiveChange} /></DataGridColumn>
				<DataGridColumn className={theme.column}>
					<div className={theme.links}>
						<LinkButton onClick={this.onEdit} iconName="icon-edit" iconSize={1.8}/>
						<LinkButton onClick={this.onDelete} iconName="icon-delete" iconSize={1.8}/>
					</div>
				</DataGridColumn>
			</DataGridRow>
		);
	}
}
