import * as React from "react";
import EditCompanyStore from "../../stores/EditCompanyStore";
import CreateCompanyStore from "../../stores/CreateCompanyStore";

export interface ICompanyContext {
	store: EditCompanyStore | CreateCompanyStore;
}

export default React.createContext<ICompanyContext>({

	store: null

});
