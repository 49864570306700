import * as React from "react";
import * as PropTypes from "prop-types";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import { AuthRoute } from "common/components";
import { appUrlsService } from "common/services";
import CreateAdvertiser from "./CreateAdvertiser";
import EditAdvertiser from "./EditAdvertiser";

export default (): JSX.Element => (
	<Switch>
		<AuthRoute path={appUrlsService.createAdvertiser()} exact component={CreateAdvertiser} />
		<AuthRoute path={appUrlsService.editAdvertiser()} exact component={EditAdvertiser} />
		<Redirect to={appUrlsService.createAdvertiser()} />
	</Switch>
);
