import { action, transaction } from "mobx";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import { notificationsStore } from "common/stores";
import ICompany from "../interfaces/ICompany";

export default abstract class BaseCompanyStore extends BaseItemStore<ICompany> {

	constructor(fromDuplicate?: ICompany) {
		super(notificationsStore, "Company");

		this.reset();

		if (fromDuplicate) {
			this.setItem(fromDuplicate);
		}
	}

	@action public reset() {
		transaction(() => {
			super.reset();

			this.item = {
				...this.item,
				name: "",
				description: "",
				legalName: "",
				gvlid: 0,
			};

		});
	}
}
