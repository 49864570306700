import * as React from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { ButtonsList, PageTitle, Modal, ConfirmPopMessage, Button, Loader, Label, PageMessage, PageContainer } from "@vidazoo/ui";
import { RouteComponentProps } from "react-router";
import * as theme from "styles/baseList.scss";
import { appUrlsService } from "common/services";
import { AddItemButton } from "common/components";
import ICompany from "../../interfaces/ICompany";
import CompaniesList from "./CompaniesList";
import CompaniesStore from "../../stores/CompaniesStore";

export interface ICompaniesProps extends RouteComponentProps<any> {
}

export interface ICompaniesState {
	activeBulkAction: string;
}

class Companies extends React.Component<ICompaniesProps, ICompaniesState> {

	private store: CompaniesStore;

	constructor(props, context) {
		super(props, context);

		this.state = { activeBulkAction: null };

		this.store = new CompaniesStore();
	}

	public componentDidMount() {
		this.store.getItems();
	}

	private cancelDeleteItem = () => {
		this.store.setPromptDeleteItem(null);
	}

	private onAddItem = () => {
		const { history } = this.props;

		history.push(appUrlsService.createCompany());
	}

	private onEditItem = (item: ICompany) => {
		const { history } = this.props;

		history.push(appUrlsService.editCompany(item._id));
	}

	private onBulkActionClick = (e) => {
		const action = e.currentTarget.name;

		this.setState({ activeBulkAction: action });
	}

	private dismissBulkAction = () => {
		this.setState({ activeBulkAction: null });
	}

	private confirmBulkAction = () => {
		const { activeBulkAction } = this.state;

		this.dismissBulkAction();

		this.store.applyBulkAction(activeBulkAction);
	}

	private toggleAllItems = () => {
		this.store.toggleAllItems(false);
	}

	private renderLoader(): JSX.Element {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader} />
				<PageMessage
					small
					title="Loading Companies"
					description="Please wait..."
				/>
			</div>
		);
	}

	public render(): JSX.Element {

		const { activeBulkAction } = this.state;

		return (
			<PageContainer pageTitle="Companies" className={theme.list} top={1.2}>
				<div className={theme.actions}>
					<PageTitle iconName="icon-campaigns" label="Companies" />
					<div className={theme.buttons}>
						<AddItemButton label="Create Company" iconName="icon-plus-small" medium blue onClick={this.onAddItem} />
						<ButtonsList label="Bulk Action" disabled={!this.store.selectedValues.length}>
							<Button onClick={this.onBulkActionClick} name="delete">Delete</Button>
							<Button onClick={this.onBulkActionClick} name="activate">Activate</Button>
							<Button onClick={this.onBulkActionClick} name="deactivate">Deactivate</Button>
						</ButtonsList>
					</div>
				</div>

				{!this.store.isLoading ? (
					<CompaniesList
						items={this.store.filteredItems}
						sortBy={this.store.sortBy}
						sortDirection={this.store.sortDirection}
						searchQueries={this.store.searchQueries}
						onDelete={this.store.setPromptDeleteItem}
						onEdit={this.onEditItem}
						onActiveChange={this.store.toggleActiveState}
						onSearch={this.store.setSearchQuery}
						onSort={this.store.sort}
						density={this.store.density}
						loadMore={this.store.loadMore}
						allItemsChecked={this.store.allItemsChecked}
						selectedValues={this.store.selectedValues}
						toggleItem={this.store.toggleItem}
						toggleAllItems={this.toggleAllItems}
						setColumnsWidth={this.store.setColumnsWidth}
						getColumnsWidth={this.store.getColumnsWidth}
					/>
				) : null}

				{this.store.isLoading ? this.renderLoader() : null}

				<Modal external exitOnClickOutside onClose={this.cancelDeleteItem} isOpen={!!this.store.promptDeleteItem}>
					<ConfirmPopMessage
						red
						title="Delete Company?"
						text={<span>Are you sure you want to delete {this.store.promptDeleteItem ? <Label weight={600} block={false}>{`${this.store.promptDeleteItem.name}`}</Label> : ""}?</span>}
						cancelText="No, Cancel"
						confirmText="Yes, Delete"
						onCancelClick={this.cancelDeleteItem}
						onConfirmClick={this.store.deleteItem}
					/>
				</Modal>
				<Modal external exitOnClickOutside onClose={this.dismissBulkAction} isOpen={!!activeBulkAction}>
					<ConfirmPopMessage
						yellow
						title="Confirm action"
						text={`Are you sure you want to ${activeBulkAction} ${this.store.selectedValues.length} Companies?`}
						cancelText="Cancel"
						confirmText="Confirm"
						onCancelClick={this.dismissBulkAction}
						onConfirmClick={this.confirmBulkAction}
					/>
				</Modal>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(Companies));
