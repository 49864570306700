import * as React from "react";
import classnames from "classnames";
import * as theme from "styles/_baseForm.scss";
import {observer} from "mobx-react";
import {FormInput, Label, AggregateChangeComponent, Checkbox, FormChips, FormSelect} from "@vidazoo/ui";
import {FormMode, normalizeEventValue} from "@vidazoo/ui-framework";
import CompanyContext, {ICompanyContext} from "./CompanyContext";

export interface IDetailsFormProps {

	mode: FormMode;

}

export interface IDetailsFormState {

}

@observer
export default class DetailsForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

	public static defaultProps: Partial<IDetailsFormProps> = {};

	public static contextType = CompanyContext;

	constructor(props: IDetailsFormProps, context: ICompanyContext) {
		super(props, context);
	}

	private onChangeDetail = (e) => {
		const {store} = this.context;

		const value = normalizeEventValue(e);

		store.updateParam(e.target.name, value);
	}

	public render(): JSX.Element {

		const {store} = this.context;

		return (
			<div className={theme.form}>
				<div className="flex">
					<div className="flex-1">
						<Label size={13} weight={700} spacing={1.25} monserrat>General Details</Label>
					</div>
					<div className={theme.header}></div>
				</div>
				<AggregateChangeComponent onChange={this.onChangeDetail}>
					<div className={theme.row}>
						<FormInput value={store.item.name} autoFocus className={theme.input} name="name" label="Name"
								   required fill/>
					</div>
					<div className={theme.row}>
						<FormInput value={store.item.description} multiline rows={2} className={theme.input}
								   name="description" label="Description" fill/>
					</div>
					<div className={theme.row}>
						<FormInput value={store.item.legalName} className={theme.input} name="legalName"
								   label="Legal Name"
								   fill/>
						<FormInput type="number" value={store.item.gvlid} className={theme.input} name="gvlid"
								   label="gvlid"
								   fill/>
					</div>
					<div className={classnames(theme.row, "flex")}
						 style={{display: "grid", gridTemplateColumns: "repeat(1fr)", gridGap: "2rem 8rem"}}>
						<Checkbox name="isActive" label="Active" defaultChecked={store.item.isActive}/>
					</div>
				</AggregateChangeComponent>
			</div>
		);
	}
}
