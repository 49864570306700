import { action } from "mobx";
import applicationsAPI from "../api/advertisersAPI";
import { navigationStore } from "common/stores";
import BaseAdvertiserStore from "./BaseAdvertiserStore";
import { appUrlsService } from "common/services";

export default class CreateAdvertiserStore extends BaseAdvertiserStore {

	@action public submit() {
		const item = this.setAccounts();

		applicationsAPI.create(item)
			.then(() => navigationStore.push(appUrlsService.advertisers()))
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}
}
