import * as React from "react";
import * as moment from "moment";
import * as theme from "./theme.scss";
import {observer} from "mobx-react";
import {Switch, LinkButton, DataGridColumn, Checkbox, DataGridRow} from "@vidazoo/ui";
import {Link} from "react-router-dom";
import {appUrlsService} from "common/services";
import ICompany from "../../interfaces/ICompany";

export interface ICompanyRowProps {
	item: ICompany;
	sortBy: string;
	onActiveChange: (item: ICompany) => void;
	onEdit: (item: ICompany) => void;
	onDelete: (item: ICompany) => void;
	onToggle: (id: string) => void;
	isSelected: boolean;
	columnsWidth: any;
	freezeColumnIndex: number;
	index: number;
}

export interface ICompanyRowState {
}

@observer
export default class CompanyRow extends React.Component<ICompanyRowProps, ICompanyRowState> {

	private onActiveChange = () => {
		const {onActiveChange, item} = this.props;

		onActiveChange(item);
	}

	private onDelete = () => {
		const {onDelete, item} = this.props;

		onDelete(item);
	}

	private onEdit = () => {
		const {item, onEdit} = this.props;

		onEdit(item);
	}

	private onToggle = () => {
		const {item, onToggle} = this.props;

		onToggle(item._id);
	}

	public render(): JSX.Element {
		const {item, index, columnsWidth, freezeColumnIndex, isSelected} = this.props;

		return (
			<DataGridRow className={theme.row} index={index} columnsWidth={columnsWidth} key={index} freezeColumnIndex={freezeColumnIndex}>
				<DataGridColumn className={theme.column}><Checkbox checked={isSelected} onChange={this.onToggle}/></DataGridColumn>
				<DataGridColumn className={theme.column}><Link to={appUrlsService.editCompany(item._id)}>{item.name}</Link></DataGridColumn>
				<DataGridColumn className={theme.column}>{item.legalName}</DataGridColumn>
				<DataGridColumn className={theme.column}>{item.gvlid}</DataGridColumn>
				<DataGridColumn className={theme.column}>{moment(item.created).format("MMM DD, YYYY")}</DataGridColumn>
				<DataGridColumn className={theme.column}><Switch small checked={item.isActive} onChange={this.onActiveChange} /></DataGridColumn>
				<DataGridColumn className={theme.column}>
					<div className={theme.links}>
						<LinkButton onClick={this.onEdit} iconName="icon-edit" iconSize={1.8}/>
						<LinkButton onClick={this.onDelete} iconName="icon-delete" iconSize={1.8}/>
					</div>
				</DataGridColumn>
			</DataGridRow>
		);
	}
}
