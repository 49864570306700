import {action, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import companiesAPI from "../api/companiesAPI";
import {notificationsStore} from "common/stores";
import ICompany from "../interfaces/ICompany";
import {SortDirection} from "@vidazoo/ui";
import INetwork from "../../../common/interfaces/INetwork";
import IAccount from "../../../common/interfaces/IAccount";
import {storageService} from "../../../common/services";

const LIST_SEARCHABLE_FIELDS = ["name"];
const LIST_FIELDS = {name: 1, legalName: 1, gvlid: 1, created: 1, isActive: 1};

export default class CompaniesStore extends BaseListStore<ICompany, IAccount, INetwork> {

	constructor() {
		super(notificationsStore, LIST_SEARCHABLE_FIELDS, "Companies", "created");
	}

	@action
	public reset() {
		transaction(() => {
			super.reset("created");
			this.sortDirection = SortDirection.DESC;
		});
	}

	@action public getItems = () => {
		if (this.isLoading) {
			return;
		}

		transaction(() => {
			this.isLoading = true;
			this.selectedValues = [];
			this.resetSearchQueries();
			this.setPromptDeleteItem(null);
		});

		const sort = {
			[this.sortBy]: this.sortDir
		};

		const filter: any = {};

		companiesAPI.getAll(1, 10000, filter, LIST_FIELDS, sort)
			.then((res) => this.onLoadItemsSuccess(res))
			.catch((res) => this.onLoadItemsError(res));
	}

	@action public toggleActiveState = (item: ICompany) => {
		item.isActive = !item.isActive;

		companiesAPI.active(item._id, item.isActive)
			.then(() => this.onUpdateStateSuccess())
			.catch(() => this.onUpdateStateError(item));
	}

	@action public deleteItem = () => {
		companiesAPI.delete(this.promptDeleteItem._id)
			.then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
			.catch(() => this.onDeleteItemError());
	}

	protected deleteSelectedValues() {
		return Promise.all(this.selectedValues.map((x) => companiesAPI.delete(x)));
	}

	protected setActiveSelectedValues(state: boolean) {
		return Promise.all(this.selectedValues.map((x) => companiesAPI.active(x, state)));
	}

	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		storageService.setColumnsWidth(`companies`, columnsWidth);
	}

	public getColumnsWidth = (): { [index: string]: number } => {
		return storageService.getColumnsWidth(`companies`);
	}
}
