import { action } from "mobx";
import * as _ from "lodash";
import companiesAPI from "../api/companiesAPI";
import { navigationStore } from "common/stores";
import { appUrlsService } from "common/services";
import { fastClone } from "@vidazoo/ui-framework";
import BaseCompanyStore from "./BaseCompanyStore";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import { Entity } from "common/enums";

export default class EditCompanyStore extends BaseCompanyStore {

	@action public getItem(id: string) {
		this.isLoading = true;
		this.loadError = false;

		companiesAPI.getOne(id)
			.then((item) => this.setItem(item.data))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public submit() {
		companiesAPI.update(this.item._id, this.item)
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public delete() {
		companiesAPI.delete(this.item._id)
			.then(() => this.onSubmitSuccess())
			.then(() => navigationStore.push(appUrlsService.companies()))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action public duplicate() {
		const item = fastClone(this.item);

		_.unset(item, "_id");

		item.name = "";

		duplicateEntityManager.setEntityDuplication(Entity.Advertiser, item);

		navigationStore.push(appUrlsService.createAdvertiser());
	}
}
