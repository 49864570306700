import IMenuEntry from "common/interfaces/IMenuEntry";
import { appUrlsService } from "common/services";
import { DEFAULT_ACCESS } from "./enums";

export default [
	{
		title: "Advertisers",
		iconName: "icon-scenarios",
		link: { to: appUrlsService.advertisers() },
		access: DEFAULT_ACCESS
	},
	{
		title: "Companies",
		iconName: "icon-campaigns",
		link: { to: appUrlsService.companies() },
		access: DEFAULT_ACCESS
	},
] as IMenuEntry[];
