import {BaseAPI} from "@vidazoo/ui-framework";

class AdvertisersAPI extends BaseAPI {
	public getAll(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = {}) {
		return this.request({
			method: "get",
			url: "/api/advertisers/",
			params: {page, page_size, filter, fields, sort}
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/advertisers/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/advertisers/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/advertisers/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/advertisers/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/advertisers/${id}/active/`,
			data: {isActive}
		});
	}
}

export default new AdvertisersAPI(window.__BASE_API_URL__);
